import http from '@utils/request'

// 系统公告
export const getNotice = (type: string,title: string) => {
    return http({
        url: '/system_api/getNoticeByPersonneltype',
        method: "POST",
        params: {
            title,
            type
        }
    })
}  
//保存修改密码   
export const resetPassword = (oldpassword: string,newpassword: string) => {
    return http({
        url: '/login_api/resetPassword',
        method: "POST",
        params: {
            oldpassword,
            newpassword
        }
    })
}
//待办事项
export const getTodoList = () => {
    return http({
        url: '/login_api/getTodoList',
        method: "POST"
    })
}