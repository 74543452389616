/*工具类*/
import printJS from "print-js";
import * as XLSX from "xlsx";
import FileSaver from 'file-saver';
import {ElNotification} from "element-plus";

export default () => {

    /*省略*/
    const omit = (v: string, num: number, symbol: string) => {
        if (v.length <= num) {
            return v;
        } else {
            return v.substring(0, num) + symbol
        }
    }

    /*日期截取*/
    const date_cut = (v: string, placeholder: string, index: number) => {
        return v.split(placeholder)[index]
    } 

    //转货币
    const toPsyt = (num: string) => {
        if (num) {
            const _num = parseFloat(num).toFixed(2)  // 保留两位
            // 获取整数部分
            const intPart = Math.trunc(Number(_num))
            // 整数部分处理，增加,
            const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
            // 预定义小数部分
            let floatPart = ''
            // 将数值截取为小数部分和整数部分
            const valueArray = _num.toString().split('.')
            if (valueArray.length === 2) { // 有小数部分
                floatPart = valueArray[1].toString() // 取得小数部分
                return intPartFormat + '.' + floatPart
            }
            return intPartFormat + floatPart
        }else{
            return ""
        }
    }
    //转货币
    const toPsytzero = (num: string) => {
        const _num = parseFloat(num).toFixed(2)  // 保留两位
        // 获取整数部分
        const intPart = Math.trunc(Number(_num))
        // 整数部分处理，增加,
        const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
        // 预定义小数部分
        let floatPart = ''
        // 将数值截取为小数部分和整数部分
        const valueArray = _num.toString().split('.')
        if (valueArray.length === 2) { // 有小数部分
            floatPart = valueArray[1].toString() // 取得小数部分
            return intPartFormat + '.' + floatPart
        }
        return intPartFormat + floatPart
    }
    //密码设置限制
    const pwdCheck = (pwd:string)=>{
        if(pwd.length<8){
            return false ;
        }else if(pwd.length>20){
            return false ;
        }else if(checkPwdType(pwd)<3){
            return false
        }else{
            return true
        }
    }
    const checkPwdType= (pwd:string)=>{ 
        let ls = 0;
        if(pwd.match(/([a-z])+/)){ 
            ls++; 
        }
        if(pwd.match(/([0-9])+/)){
            ls++; 
        }
        if(pwd.match(/([A-Z])+/)){      
            ls++; 
        }
        if(pwd.match(/[^a-zA-Z0-9]+/)){
            ls++;   
        }
        return ls ;
    }
    const checkUsername= (val:string)=>{ 
        val = val.replace(/[^a-zA-Z0-9]/g, '')
        return val ;
    }
    //用户状态：启用/停用
    const userStatus = (val:number) => {
        if(val==0){
            return '停用'
        }else if(val==1){
            return '启用'
        }else{
            return ''
        }
    }
    //申请状态
    const applyStatus = (val:number) => {
        if(val==-1){
            return '退回'
        }else if(val==-2){
            return '取消'
        }else if(val==0){
            return '待补充'
        }else if(val==1){
            return '待医保局初审'
        }else if(val==2){
            return '待医保局复审'
        }else if(val==3){
            return '确认单待生成'
        }else if(val==4){
            return '已完成'
        }else if(val==5){
            return '诉讼调解信息待补充'
        }else{
            return ''
        }
    }
    const ybjStatus = (val:number) => {
        if(val==-1){
            return '退回'
        }else if(val==-2){
            return '取消'
        }else if(val==0){
            return '待补充'
        }else if(val==1){
            return '待医保局初审'
        }else if(val==2){
            return '待医保局复审'
        }else if(val==3){
            return '确认单待生成'
        }else if(val==4||val==5){
            return '已完成'
        }else{
            return ''
        }
    }
    //是否符合医保报销政策状态
    const ybbxStatus = (val:number,sfry:string,sffhybbx:string) => {
        if(sfry=='是'){
            if(val==4||val==5){
                return sffhybbx ;
            }else{
                return ''
            }
        }else{
            return '未申请医保报销'
        }
    }
    //根据状态显示待办0申请人，1保险公司，2交警部门，3医院，4法院，5医保局）
    const getTodoStatus = (status:number,type:number)=>{
        let content = ''
        if(type==2){
            if(status==0||status==1||status==5){
                content = '待补充事故责任认定书'
            }
        }else if(type==4){
            content = '待补充判决书材料'
        }else if(type==5){
            if(status==1){
                content = '申请资料待初审'
            }else if(status==2){
                content = '申请资料待复审'
            }else if(status==3){
                content = '确认单待生成'
            }
        }
        return content
    }
    //非数值情况
    const clearnoNum = (obj: any, attr: string) => {
        if (obj[attr] == '' || obj[attr] == null) {
            obj[attr] = 0;
            return;
        }
        obj[attr] = obj[attr].toString();
        obj[attr] = obj[attr].replace(/[^\d.]/g, "");
        //保证只有出现一个.而没有多个.
        obj[attr] = obj[attr].replace(/\.{2,}/g, "");
        //保证.只出现一次，而不能出现两次以上
        obj[attr] = obj[attr].replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
        const money = parseFloat(obj[attr]) || 0;
        obj[attr] = money.toFixed(2);
    }
    const clearnoNumonly = (obj: any, attr: string) => {
        if (obj[attr] == '' || obj[attr] == null) {
            obj[attr] = 0;
            return;
        }
        obj[attr] = obj[attr].replace(/\D/g, "")
        const money = parseFloat(obj[attr]) || 0;
        obj[attr] = money.toFixed(0);
    }

    /*只能输入整数，不四舍五入*/
    const onInt = (obj: any, attr: string) => {
        if (obj[attr] == '' || obj[attr] == null || isNaN(obj[attr])) {
            obj[attr] = 0;
            return;
        }
        obj[attr] = parseInt(obj[attr])
    }

    //金额数字转大写
    const convertCurrency = (Num: string) => {
        Num = Num.replace(/,/g, '') // 替换tomoney()中的“,”
        Num = Num.replace(/ /g, '') // 替换tomoney()中的空格
        Num = Num.replace(/￥/g, '') // 替换掉可能出现的￥字符
        // 字符处理完毕后开始转换，采用前后两部分分别转换
        const part = String(Num).split('.')
        let newchar = ''
        // 小数点前进行转化
        for (let i = part[0].length - 1; i >= 0; i--) {
            if (part[0].length > 10) {
                // return "";
                // 若数量超过拾亿单位，提示
            }
            let convert = ''
            const perchar = part[0].charAt(i)
            switch (perchar) {
                case '0':
                    convert = "零" + convert
                    break
                case '1':
                    convert = '壹' + convert
                    break
                case '2':
                    convert = '贰' + convert
                    break
                case '3':
                    convert = '叁' + convert
                    break
                case '4':
                    convert = '肆' + convert
                    break
                case '5':
                    convert = '伍' + convert
                    break
                case '6':
                    convert = '陆' + convert
                    break
                case '7':
                    convert = '柒' + convert
                    break
                case '8':
                    convert = '捌' + convert
                    break
                case '9':
                    convert = '玖' + convert
                    break
            }
            switch (part[0].length - i - 1) {
                case 0:
                    convert = convert + '元'
                    break
                case 1:
                    if (Number(perchar) !== 0) convert = convert + '拾'
                    break
                case 2:
                    if (Number(perchar) !== 0) convert = convert + '佰'
                    break
                case 3:
                    if (Number(perchar) !== 0) convert = convert + '仟'
                    break
                case 4:
                    convert = convert + '万'
                    break
                case 5:
                    if (Number(perchar) !== 0) convert = convert + '拾'
                    break
                case 6:
                    if (Number(perchar) !== 0) convert = convert + '佰'
                    break
                case 7:
                    if (Number(perchar) !== 0) convert = convert + '仟'
                    break
                case 8:
                    convert = convert + '亿'
                    break
                case 9:
                    convert = convert + '拾'
                    break
            }
            newchar = convert + newchar
        }
        // 小数点之后进行转化
        if (Num.indexOf('.') !== -1) {
            if (part[1].length > 2) {
                // alert("小数点之后只能保留两位,系统将自动截断");
                part[1] = part[1].substr(0, 2)
            }
            for (let j = 0; j < part[1].length; j++) {
                let convert = ''
                const perchar = part[1].charAt(j)
                switch (perchar) {
                    case '0':
                        convert = '零' + convert
                        break
                    case '1':
                        convert = '壹' + convert
                        break
                    case '2':
                        convert = '贰' + convert
                        break
                    case '3':
                        convert = '叁' + convert
                        break
                    case '4':
                        convert = '肆' + convert
                        break
                    case '5':
                        convert = '伍' + convert
                        break
                    case '6':
                        convert = '陆' + convert
                        break
                    case '7':
                        convert = '柒' + convert
                        break
                    case '8':
                        convert = '捌' + convert
                        break
                    case '9':
                        convert = '玖' + convert
                        break
                }
                if (j === 0) convert = convert + '角'
                if (j === 1) convert = convert + '分'
                newchar = newchar + convert
            }
        }
        // 替换所有无用汉字
        while (newchar.search('零零') !== -1) {
            newchar = newchar.replace('零零', '零')
        }
        newchar = newchar.replace('零亿', '亿')
        newchar = newchar.replace('亿万', '亿')
        newchar = newchar.replace('零万', '万')
        newchar = newchar.replace('零元', '元')
        newchar = newchar.replace('零角', '')
        newchar = newchar.replace('零分', '')
        if (newchar.charAt(newchar.length - 1) === '元') {
            newchar = newchar + '整'
        }
        return newchar
    }

    /**/
    const correspond = (v: string, arry: [], key: string) => {
        for (let i = 0; i < arry.length; i++) {
            if (arry[i][key] == v) {
                return arry[i]
            }
        }
    }

    const hasKV = (v: string, arry: [], key: string) => {
        for (let i = 0; i < arry.length; i++) {
            if (arry[i][key] == v) {
                return false
            }
        }
        return true
    }

    const inArry = (v: string, arry: string[]) => {
        for (let i = 0; i < arry.length; i++) {
            if (arry[i] == v) {
                return true
            }
        }
        return false
    }

    const El_notice = (title: string, message: string, type: 'error' | 'success' | 'info' | 'warning') => {
        ElNotification({
            title: title,
            message: message,
            type: type,
        })
    }

    /*表格打印*/
    const print = (id: string) => {
        printJS({
            printable: id,
            type: 'html',
            targetStyles: ["*"],
            scanStyles: false,
            style: `
                @page {size:auto;}
                thead th {
                  border-top: 1px solid #000;
                  border-right: 1px solid #000;
                  border-left: 1px solid #000;
                }
                tbody td {
                  border: 1px solid #000;
                }
                tbody {
                  text-align: center;
                }
                table {
                  border-collapse: collapse;
                }
                .no_print {
                    display:none
                }
                @media print{@page {size:landscape}}
            `,
        })
    }
    const printTable = (tb_id: string, has_foot: boolean) => {
        const tb_tmp = document.createElement('div')
        tb_tmp.setAttribute("id", 'temporary_table')
        tb_tmp.setAttribute("style", 'position: absolute; z-index:-10;')
        document.getElementsByClassName('el-main').item(0)!.appendChild(tb_tmp);
        const th_head = document.getElementById(tb_id)!.getElementsByClassName('el-table__header').item(0)!.cloneNode(true)
        const th_body = document.getElementById(tb_id)!.getElementsByClassName('el-table__body').item(0)!.cloneNode(true)
        tb_tmp!.appendChild(th_head)
        tb_tmp!.appendChild(th_body)
        if (has_foot) {
            const th_footer = document.getElementById(tb_id)!.getElementsByClassName('el-table__footer').item(0)!.cloneNode(true)
            tb_tmp!.appendChild(th_footer)
        }
        setTimeout(() => {
            print('temporary_table')
            document.getElementsByClassName('el-main').item(0)!.removeChild(tb_tmp)
        }, 500)
    }

    const expExcel = (id: string, filename: string) => {
        const xlsxParam = { raw: true }
        const wb = XLSX.utils.table_to_book(document.querySelector('#' + id), xlsxParam);//关联dom节点
        const wb_out = XLSX.write(wb, {
            bookType: 'xlsx',
            bookSST: true,
            type: 'array'
        });
        try {
            FileSaver.saveAs(new Blob([wb_out], {
                type: 'application/octet-stream'
            }), filename + '.xlsx')//自定义文件名
        } catch (e) {
            if (typeof console !== 'undefined') console.log(e, wb_out);
        }
        return true
    }
 
    const bytesToSize = (bytes: number) => {
        if (bytes === 0) return '0 B';
        const k = 1024,
            sizes = ['B', 'KB', 'M', 'G'],//到GB即可，目前不需要这么大的单位，甚至到M
            i = Math.floor(Math.log(bytes) / Math.log(k));

        return (bytes / Math.pow(k, i)).toPrecision(3) + '' + sizes[i];
    } 

    const bmjbsx_remark = (row: any) => {
        let remark = "" ;
        if(row.ryzc !=null && row.ryzc!=''){
            remark = remark + row.ryzc.name ;
        }
        if(row.gyzc !=null && row.gyzc!=''){
            remark = remark  +"-"+ row.gyzc.name ;
        }
        if(row.spfwqtzbzc !=null && row.spfwqtzbzc!=''){
            remark = remark  +"-"+ row.spfwqtzbzc.name ;
        }
        return remark ;
    }
    //手机号校验
    const checkPhone = (phone:string) => {
        if(!(/^1[3456789]\d{9}$/.test(phone))){
            return false
        }else{
            return true
        }
    }
    //邮箱校验
    const emailonchange = (email:string)=>{
        const reg=/^[a-z0-9](\w|\.|-)*@([a-z0-9]+-?[a-z0-9]+\.){1,3}[a-z]{2,4}$/i;
        if(reg.test(email)){
            return true
        }else{
            return false
        }
    }
    //待办事项
    const todoContent = (obj:any)=>{
        let val = "事故分类:" + obj.accidenttype   ;
        return val ;
    }
    //获取当前时间
    const getNowTime = () =>{
        let times = new Date()
        let time = times.toLocaleString('en-US',{hour12: false}).split(" ")[1]
        let mdy = times.toLocaleString('en-US',{hour12: false}).split(" ")[0]
        let mdysz = mdy.split('/')
        let month = parseInt(mdysz[0]);
        let day = parseInt(mdysz[1]);
        let year = parseInt(mdysz[2])
        if(month>9){
            return year + '-' + month + '-' + day + ' ' + time
        }else{
            return year + '-' + "0" + month + '-' + day + ' ' + time
        }
    }
    //公告查看人员类型
    const getNoticeRy = (type:number) =>{
        let value = ""
        switch (type) {
            case 0:
                value = "申请人员"
                break
            case 1:
                value = "保险公司人员"
                break
            case 2:
                value = "交警部门人员"
                break
            case 3:
                value = "医院医师"
                break
            case 4:
                value = "法院人员"
                break
            case 5:
                value = "医保局人员"
                break
            default:
                value = ""
        }
        return value
    }
    //是否申请诉讼
    const getSfSs = (isss:number) =>{
        if(isss!=null){
            if(isss==1){
                return '否'
            }else if(isss==2){
                return '是'
            }else{
                return '暂未选择'
            }
        }else{
            return '暂未选择'
        }
    }
    //与申请人的关系
    const getRelationship = (type:number) =>{
        let value = ""
        switch (type) {
            case 0:
                value = "本人"
                break
            case 1:
                value = "配偶"
                break
            case 2:
                value = "父母"
                break
            case 3:
                value = "子女"
                break
            case 4:
                value = "其他"
                break
            default:
                value = ""
        }
        return value
    }
    //姓名脱敏
    const noPassByName = (name:string) => {
        if(name != null && name !=undefined ){
            if(name.length == 2){
                return name.substring(0,1) + '*'
            }else if(name.length == 3){
                return name.substring(0,1) + '*' + name.substring(2,3)
            }else if(name.length>3){
                return name.substring(0,1) + '*' + '*' + name.substring(3,name.length) ;
            }
        }
        return ''
    }
    return {
        omit,
        date_cut, 
        pwdCheck,
        checkUsername,
        userStatus,
        applyStatus,
        ybbxStatus,
        toPsyt,
        toPsytzero,
        clearnoNum,
        clearnoNumonly,
        convertCurrency,
        correspond, 
        printTable,
        expExcel, 
        hasKV,
        onInt,
        inArry,
        bytesToSize, 
        bmjbsx_remark,
        El_notice,
        checkPhone,
        emailonchange,
        todoContent,
        getNowTime,
        getNoticeRy,
        getTodoStatus,
        getSfSs,
        getRelationship,
        noPassByName,
        ybjStatus
    }
}