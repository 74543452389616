import axios, {AxiosRequestHeaders} from "axios";
import {userInfoStore} from "@/store/user";

const instance = axios.create({
    // baseURL: "/mock",
    baseURL: "/newapi",
    timeout: 60000,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8'
    }
})

instance.interceptors.request.use(
    req => {
        const user_store = userInfoStore();
        const token = user_store.token;
        if( token ) {
            (req.headers as AxiosRequestHeaders)['micro_token_name'] = token
        }
        return req;
    },
    error => {
        console.log(error);
    }
)

instance.interceptors.response.use(
    res => {
        if(res.headers['content-disposition']){ //将响应文件名信息直接添加挂载rea.data
            res.data.headers =  res.headers['content-disposition']
        }
        return res.data;
    },
    error => {
        console.log(error);
    }
)

export default instance