import {defineStore} from "pinia";
import {nav_type, router_type} from "@/typings/types";

export const navStore = defineStore('nav', {
    state: () => {
        return {
            Collapse: true,
            registerRouteFresh: true,
            sidebarWidth: '64px',
            showSiderCollapse: true,
            elMainH: "calc(100vh - 124px)",
            elAsideH: "calc(100vh - 59px)",
            menuType: '',
            tabIndex: '/home',
            htmlTitle: '',
            navBars: [] as nav_type[],
            routers: [] as router_type[],
            siderRouters: [] as router_type[],
            tabs: [
                {
                    title: '首页',
                    path: '/home',
                    icon: 'house'
                }
            ]
        }
    },
    getters: {},
    actions: {
        /*侧边栏开关*/
        isCollapse(flag: boolean) {
            this.Collapse = flag
            if (!flag) {
                this.sidebarWidth = '250px'
            } else {
                this.sidebarWidth = '64px'
            }
        },
        changeSiderWidth() {
            this.sidebarWidth = '64px'
        },
        isShowSiderCollapse(flag: boolean) {
            this.showSiderCollapse = flag
        },
        /*记录浏览器标题*/
        setHtmlTitle(v: string) {
            if (v != undefined) {
                this.htmlTitle = v
            }
        },
        /*添加页面*/
        addTab(route: object) {
            const notTab = this.tabs.findIndex((item) => item.path == (<any>route).fullPath) == -1;
            if (notTab) {
                this.tabs.push({
                    title: (<any>route).meta.title,
                    path: (<any>route).fullPath,
                    icon: (<any>route).meta.icon
                })
            }
            this.tabIndex = (<any>route).fullPath
        },
        /*删除页面*/
        removeTab(targetName: string) {
            const tabs = this.tabs
            let activeName = this.tabIndex
            if (activeName === targetName) {
                tabs.forEach((tab, index) => {
                    if ((tab as any).path === targetName) {
                        const nextTab = tabs[index + 1] || tabs[index - 1]
                        if (nextTab) {
                            activeName = (<any>nextTab).path
                        }
                    }
                })
            }
            this.tabIndex = activeName
            this.tabs = tabs.filter((tab) => (<any>tab).path !== targetName)
        },
        /*删除页面*/
        closeAll() {
            this.tabIndex = '/home'
            this.tabs = [
                {
                    title: '首页',
                    path: '/home',
                    icon: 'house'
                }
            ]
        },
        closeOther() {
            const tabs = this.tabs
            this.tabs = tabs.filter((tab) => (<any>tab).path == this.tabIndex || (<any>tab).path == '/home')
        },
        setMenuType(v: string) {
            this.menuType = v
        },
        generateNavBar(nav: nav_type[]) {
            this.navBars = nav
        },
        generateRouter(router: router_type[]) {
            const newRouters = router?.map(item => {
                const routes = {
                    path: item.path,
                    name: item.name,
                    meta: item.meta,
                    component: () => import(`@views/${item.component}`),
                }
                if (item.redirect) {
                    (<any>routes).redirect = item.redirect
                }
                if (item.children) {
                    (<any>routes).children = this.generateRouter((<any>item).children)
                }
                return routes
            })
            this.routers = router;
            return newRouters
        },
        setSiderRouter(router: router_type[]) {
            this.siderRouters = router
        },
        clearLocalStorage() {
            this.menuType = ""
            this.tabIndex = '/login'
            this.htmlTitle = ""
            this.navBars = []
            this.routers = []
            this.siderRouters = []
            this.tabs = [
                {
                    title: '首页',
                    path: '/home',
                    icon: 'house'
                }
            ]
            this.showSiderCollapse = true
            this.sidebarWidth = '64px'
            this.registerRouteFresh = true
        },
        changeLogin() {
            this.menuType = ""
            this.htmlTitle = ""
            this.navBars = []
            this.routers = []
            this.siderRouters = []
            this.sidebarWidth = '64px'
            this.showSiderCollapse = true
            this.tabs = [
                {
                    title: '首页',
                    path: '/home',
                    icon: 'house'
                }
            ]
        }
    },
    persist: [
        {
            key: 'nav_info',
            storage: localStorage,
            paths: ['Collapse','tabIndex', 'tabs', 'menuType', 'htmlTitle', 'siderRouters', 'sidebarWidth', 'showSiderCollapse']
        }
    ]
})