import NProgress from 'nprogress'

const progress = NProgress.configure({
    speed: 500,  // 递增进度条的速度
    showSpinner: false, // 是否显示加载ico
    trickleSpeed: 500, // 自动递增间隔
    minimum: 0.3 // 初始化时的最小百分比
})

export const startProgress = () => {
    progress.start()
}

export const doneProgress = () => {
    progress.done()
}