import {defineStore} from "pinia";
import {RoleType, UserinfoType} from "@/typings/types";

export const userInfoStore = defineStore('user', {
    state: () => {
        return {
            token: "", 
            userInfo: {} as UserinfoType,
            roleList: [] as RoleType[]
        }
    },
    getters: {},
    actions: {
        setToken(token: string) {
            this.token = token
        },
        clearInfo() {
            this.token = "" 
            this.userInfo = {} as UserinfoType,
            this.roleList = [] as RoleType[]
        }, 
        setUserInfo(data: UserinfoType) {
            this.userInfo = data
        },
        setRoleList(list: RoleType[]) {
            this.roleList = list
        }
    },
    persist: [
        {
            key: 'user_info',
            storage: localStorage,
        }
    ]
})