import http from '@utils/request'

//登录
export const UserLogin = (params:{
    username: string,
    password: string
}) =>{
    return http({
        url: '/login_api/Login',
        method: "POST",
        params
    })
}

//获取当前登录人信息
export const getUserInfo = () =>{
    return http({
        url: '/login_api/getUserInfo',
        method: "POST"
    })
}
//获取菜单项
export const getRouters = () =>{
    return http({
        url: '/login_api/allMenu',
        method: "POST"
    })
}
//首页内容模块判断
export const getModule = () =>{
    return http({
        url: '/login_api/getModule',
        method: "POST"
    })
}
