import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
/*element*/
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
/*加载条*/
import 'nprogress/nprogress.css'
/*fontawesome图标库*/
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
/*状态管理*/
import pinia from './store'
/*打印*/
import print from 'vue3-print-nb'
import mitt from 'mitt'
import ElTableInfiniteScroll from 'el-table-infinite-scroll';

// require('./mock/index')

library.add(fas)

const app = createApp(App);
app.use(router)
    .use(pinia)
    .use(print)
    .directive('el-table-infinite-scroll', ElTableInfiniteScroll)
    .component('font-icon', FontAwesomeIcon)
    .mount('#app')

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.config.globalProperties.Bus = mitt()
/*
* 图标库地址：https://fontawesome.com/search?o=r&s=light%2Csolid
* */
