import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import {navStore} from "@/store/nav";
import {userInfoStore} from "@/store/user";
import {doneProgress, startProgress} from "@utils/nprogress";
import {getRouters} from "@utils/request/login";
import {storeToRefs} from "pinia";
import Layout from "@components/layoutView/BudgetCenter.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: '/',
    redirect:'/home',
    component: Layout,
    children: [
      {
        path: 'home',
        name: 'home',
        meta: {
          types: 'home',
          title: '首页',
          icon:'house'
        },
        component: () => import(/* webpackChunkName: "home" */ '@views/HomeView.vue')
      },
      {
        path: 'menu_management',
        name: 'menu_management',
        meta: {
          types: '',
          title: '菜单管理',
          icon:'gear'
        },
        component: () => import('@views/MenuManage.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      types: 'login',
      title: '登录',
      icon:'right-to-bracket'
    },
    component: () => import(/* webpackChunkName: "home" */ '@views/LoginView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

/*
* 前置路由守卫
* 可在进入页面前进行权限，token的判断
* */
router.beforeEach((to, from, next) => {
  startProgress()
  const nav_store = navStore()
  const user_store = userInfoStore()
  const {registerRouteFresh} = storeToRefs(nav_store);
  const {token} = storeToRefs(user_store)
  if(to.path ==='/login') {
    next()
  }else if(to.path.indexOf('/fileupload')>-1) {
    next()
  }else {
    if (token.value == "") {
      next('/login')
    } else {
      if (registerRouteFresh.value) {
        getRouters().then( res => {
          // nav_store.generateNavBar(res.data.nav)
          // nav_store.generateRouter(res.data.side).forEach( r => {
          //   router.addRoute(r)
          // })
          nav_store.generateRouter(res.data).forEach( r => {
            router.addRoute(r)
          })
          registerRouteFresh.value = false
          next({path: `${to.path}`})
        })
      }else {
        nav_store.setHtmlTitle(<any>to.meta.title)
        document.title = nav_store.htmlTitle
        nav_store.addTab(to)
        next()
      }
    }
  }
})

router.afterEach(() => {
  doneProgress()
})

export default router
